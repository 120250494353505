import React, { useState } from 'react';
import LicenseFormSubmitButton from '../license-form-submit-button/license-form-submit-button';
import { getCorelliumLicense } from '../../../utils/api-licensing';
import LicenseError from '../license-error/license-error';
import '../license-form.scss';
import LicenseDisplayWithChildren from '../license-display/license-display-with-children';
import PropTypes from 'prop-types';

const Corellium = ({ labData }) => {
    const [registrationData, setRegistrationData] = useState(null);
    const [requestSuccessful, setRequestSuccessful] = useState(null);
    const [licenseError, setLicenseError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onClickHandler = async () => {
        try {
            setIsSubmitting(true);
            const licenseResponse = await getCorelliumLicense(labData);

            if (licenseResponse.error) {
                throw new Error(licenseResponse.error);
            }
            setRegistrationData({
                email: licenseResponse.license.data.email_address,
            });
            setRequestSuccessful(true);
        } catch (e) {
            setLicenseError(e.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <section>
            <LicenseFormSubmitButton
                onClick={ onClickHandler }
                disabled={ !!isSubmitting || !!requestSuccessful || !!licenseError }
                submitting={ isSubmitting }>
                Register for Corellium
            </LicenseFormSubmitButton>
            {requestSuccessful && (
                <LicenseDisplayWithChildren>
                    <p>
                        Our records indicate that you should be signed up on
                        the Corellium platform with the email address <b>{registrationData.email}.</b>
                    </p>
                </LicenseDisplayWithChildren>
            )}

            {licenseError && <LicenseError error={ licenseError } />}
        </section>
    );
};

Corellium.propTypes = {
    labData: PropTypes.object,
};

export default Corellium;
