import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getLabInstructionsByUser } from '../utils/api';
import { setRangeData } from './rangeProvisioningSlice';
import { FAILED_API_NOTFICATION } from '../constants';
import { LabProduct, RangeProvisioningResponseData } from '../types/types';

export const fetchLabResults = createAsyncThunk(
    'labs/fetchLabResults',
    async (_, { dispatch }) => {
        const { data, rangeProvisioning }= await getLabInstructionsByUser();

        dispatch(setRangeData(rangeProvisioning as RangeProvisioningResponseData));
        // eslint-disable-next-line no-use-before-define
        dispatch(setLabResults(data));
        return data;
    }
);

interface LabSliceState {
    labResults: LabProduct[];
    isLoading: boolean;
    error: { type: string; message: string } | null;
}

const INITIAL_LAB_STATE: LabSliceState = {
    labResults: [],
    isLoading: true,
    error: null,
};

export const labSlice = createSlice({
    name: 'labs',
    initialState: INITIAL_LAB_STATE,
    reducers: {
        setLabResults: (state, action: PayloadAction<LabProduct[]>) => {
            const { payload } = action;

            state.labResults = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLabResults.pending, (state) => {
            state.labResults = [];
            state.isLoading = true;
            state.error = null;
        });

        builder.addCase(fetchLabResults.fulfilled, (state, action) => {
            state.labResults = action.payload;
            state.isLoading = false;
            state.error = null;
        });
        builder.addCase(fetchLabResults.rejected, (state) => {
            state.labResults = [];
            state.isLoading = false;
            state.error = FAILED_API_NOTFICATION;
        });
    },
});

export const { setLabResults } = labSlice.actions;

export default labSlice.reducer;
