import { b64EncodeUnicode } from './b64-encode-unicode';
import { readFileToString } from './file-to-string';

/**
 * Takes a text file and returns its string contents in base64
 * @param {File} file - The text file to process
 * @returns The file's contents in base64
 */
export const convertFileStrToBase64 = async (file) => {
    try {
        const fileStr = await readFileToString(file);
        const fileB64Str = b64EncodeUnicode(fileStr);

        return fileB64Str;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        throw e;
    }
};
