/**
 * Takes a string and returns it in base64
 *
 * see: https://developer.mozilla.org/en-US/docs/Glossary/Base64#solution_1_%E2%80%93_escaping_the_string_before_encoding_it
 * @param {string} str
 * @returns A base64 encoded string
 */
export const b64EncodeUnicode = (str) => {
    return btoa(
        encodeURIComponent(str).replace(
            /%([0-9A-F]{2})/g,
            function (match, p1) {
                return String.fromCharCode('0x' + p1);
            }
        )
    );
};
