/**
 * This file is used to export all the mock data that is used in stories and tests.
 */
import { RootState } from '../app/store';
import { TEST_ACCOUNT_RESPONSE as testAccountResponse } from './account/account';
import { TestConfigState } from './redux/data';
import { TEST_USER_RESPONSE as testUserResponse } from './user/user';

/**
 * This is the test redux state that is used in stories and tests. It is a partial state that
 * contains the minimum necessary data to render the components.
 */
const testReduxState: Partial<RootState> = {
    accountReducer: testAccountResponse.data,
    configReducer: {
        config: TestConfigState(),
    },
    labReducer: {
        labResults: testUserResponse.data,
        isLoading: false,
        error: null,
    },
    filesReducer: {
        files: {},
    },
};

export {
    testAccountResponse,
    testUserResponse,
    testReduxState,
}
