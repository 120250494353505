import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RangeInstance, RangeProvisioningResponseData, RangeRule } from '../types/types';

interface RangeProvisioningState {
    courses: RangeRule[];
    ranges: RangeInstance[]
}

const INITIAL_RANGE_PROVISIONING_STATE: RangeProvisioningState = {
    courses: [],
    ranges: [],
};

export const rangeProvisioningSlice = createSlice({
    name: 'rangeProvisioning',
    initialState: INITIAL_RANGE_PROVISIONING_STATE,
    reducers: {
        setRangeData: (state, action: PayloadAction<RangeProvisioningResponseData>) => {
            const { courses, ranges } = action.payload;

            state.courses = courses.data;
            state.ranges = ranges.data;
        },
    },
});

export const { setRangeData } = rangeProvisioningSlice.actions;

export default rangeProvisioningSlice.reducer;
