import React, { useState } from 'react';
import { FileDownloader } from '../../lab-display/license-dropdown';
import PropTypes from 'prop-types';

const LicenseDownload = ({
    licenseFileBlob,
    downloadName,
    download = true,
}) => {
    const [fileUrl] = useState(URL.createObjectURL(licenseFileBlob));
    /* eslint-disable react/jsx-curly-spacing */
    return (
        <section className="licensing-download">
            <a
                data-testid="license-download-link"
                href={fileUrl}
                download={downloadName || licenseFileBlob.name || 'license.dat'}
            >
                Your file should automatically download soon. Click here if it
                does not.
            </a>
            {download && <FileDownloader fileBlob={licenseFileBlob} />}
        </section>
    );
    /* eslint-enable react/jsx-curly-spacing */
};

LicenseDownload.propTypes = {
    licenseFileBlob: PropTypes.instanceOf(Blob),
    downloadName: PropTypes.string,
    download: PropTypes.bool,
};
export default LicenseDownload;
