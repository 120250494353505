import '../license-form.scss';
import React, { useState } from 'react';
import { getCellebriteLicense } from '../../../utils/api-licensing';
import LicenseError from '../license-error/license-error';
import LicenseDownload from '../license-download/license-download';
import LicenseFormSubmitButton from '../license-form-submit-button/license-form-submit-button';
import LicenseFileUpload from '../license-file-upload/license-file-upload';
import { convertFileStrToBase64 } from '../../../utils/utilities-licensing';
import PropTypes from 'prop-types';

// eslint-disable-next-line valid-jsdoc
/**
 * Renders a form to obtain a Cellbrite License.
 *
 * Utilizes <FileDownloader /> to instantly download the license
 * if the request is valid.
 */
const CellebriteForm = ({ labData }) => {
    const [licenseError, setLicenseError] = useState(null);
    const [licenseFileBlob, setLicenseFileBlob] = useState(null);
    const [c2vBase64, setc2vBase64] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFile = async (c2vFile) => {
        try {
            const c2vBase64Str = await convertFileStrToBase64(c2vFile);

            setc2vBase64(c2vBase64Str);
            return c2vBase64Str;
        } catch (e) {
            setLicenseError('There was an error processing the provided .c2v file');
            return null;
        }
    };

    const submitLicenseRequest = async () => {
        setIsSubmitting(true);
        setLicenseError(null);
        try {
            const cellebriteLicense = await getCellebriteLicense(labData, {
                c2v: c2vBase64,
            });

            if (cellebriteLicense.license) {
                setLicenseFileBlob(cellebriteLicense.license);
            } else {
                setLicenseError(cellebriteLicense.error);
            }
        } catch (e) {
            setLicenseError(
                'Error acquiring license. ',
                e.message
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <section className="license-form">
            <div className="license-field-row">
                <div className="license-form-field">
                    <LicenseFileUpload inputLabel='Upload your .c2v file' inputAccepts=".c2v" cb={
                        handleFile } showAllowedTypes={ true } />
                </div>
            </div>
            <div className="license-field-row">
                <LicenseFormSubmitButton
                    disabled={ isSubmitting || !c2vBase64 }
                    submitting={ isSubmitting }
                    onClick={ submitLicenseRequest }>
                    Request License
                </LicenseFormSubmitButton>
            </div>
            {licenseError && <LicenseError error={ licenseError } />}
            {licenseFileBlob && (
                <LicenseDownload licenseFileBlob={ licenseFileBlob } />
            )}
        </section>
    );
};

CellebriteForm.propTypes = {
    labData: PropTypes.object,
};

export default CellebriteForm;
