/**
 * Mock response body for the account endpoint
 */
const TEST_ACCOUNT_RESPONSE = {
    result: 'success',
    data: {
        name: 'Test Account',
        sansAccountID: '12345678',
        sansEmail: 'testuser@sans.org',
    },
    features: {
        'files-api-version': 'v2',
        'licensing-api-proxy': 'on',
    },
};

export {TEST_ACCOUNT_RESPONSE};
