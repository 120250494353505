import React from 'react';
import { DynamicContainerV1 } from '../../dynamic-renderer/DynamicRendererV1';
import { DynamicContainer } from '../../dynamic-renderer/dynamic-renderer';

export const renderDynamicContentV1 = (lab, records, range, sansAccountID) => {
    return (
        <DynamicContainerV1
            lab={ lab }
            studentId={ sansAccountID }
            records={ records }
            range={ range }
        />
    );
};

export const renderDynamicContentV2 = (files, sansAccountID) => {
    return <DynamicContainer studentId={ sansAccountID } files={ files } />;
};
