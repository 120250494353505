import CloudProviderButton from './cloud-provider-button';
import React from 'react';
import PropTypes from 'prop-types';
import AppConfig from '../../../../config/appConfig.ts';

const config = AppConfig();

const DEFAULT_CLOUD_PROVIDERS = config.integrations.cloudProviders.providers.default;

/**
 * Container component for cloud provider buttons
 * @component
 * @param {Object} props
 * @param {string[]} props.providers - A string list of cloud providers
 * @param {string|null} props.text - The body text of the CloudProvider's section
 * @param {string[]} props.overrides - An array of cloud providers to override `DEFAULT_CLOUD_PROVIDERS`
 * @returns {JSX.Element}
 */
const CloudProviders = (props) => {
    const { providers } = props;
    const DEFAULT_BODY = 'Select your cloud provider from the options below.';

    return (
        <section>
            <header>Cloud Providers</header>
            <p>{props.text || DEFAULT_BODY}</p>
            <section className="flex column w-12">
                {providers.map((provider) => (
                    <CloudProviderButton
                        key={ `cloudProvider-${provider}` }
                        provider={ provider }
                        labData={ props.labData }
                    />
                ))}
            </section>
        </section>
    );
};

CloudProviders.propTypes = {
    /** A string list of cloud providers */
    providers: PropTypes.arrayOf(PropTypes.string),
    /** The body text of the CloudProvider's section */
    text: PropTypes.string,
    /** Abbreviated data from `lab` object */
    labData: PropTypes.object,
};

CloudProviders.defaultProps = {
    providers: DEFAULT_CLOUD_PROVIDERS,
    text: '',
    labData: {},
};
export default CloudProviders;
