/**
 * @prettier
 */
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { PATH } from '../../constants';
import history from '../../submodules/shared/utils/history';

import Home from '../../views/home/home';
import { useEffect } from 'react';
import { getAccountDetails } from '../../utils/api';
import { useAppDispatch } from '../../app/store';
import { setAccountDetails } from '../../features/accountSlice';
import { fetchLabResults } from '../../features/labSlice';
import { SimpleDialogContainer } from 'react-simple-dialogs';
import { setForwardedValue } from '../../config/features/flags';

const App = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        const fetchInitialMyLabsData = async () => {
            try {
                const accountDetailsResponse = await getAccountDetails();

                if (accountDetailsResponse.features) {
                    Object.entries(accountDetailsResponse.features).forEach(
                        ([key, value]) => {
                            if (key && value) {
                                setForwardedValue(key, value);
                            }
                        }
                    );
                }

                dispatch(setAccountDetails(accountDetailsResponse.data));

                dispatch(fetchLabResults());
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
            }
        };

        fetchInitialMyLabsData();
    }, []);

    return (
        <div className="App">
            <Router history={ history } >
                <div className="router">
                    <Switch>
                        <Route path={ PATH.home } component={ Home }/>
                        <Redirect from={ PATH.base } to={ PATH.home }/>
                    </Switch>
                </div>
            </Router>
            <SimpleDialogContainer primaryColor="#ff0000" primaryHoverColor="#ff9999" />
        </div>
    );
};

App.propTypes = {
    messages: PropTypes.array,
    clearNotifications: PropTypes.func,
    hideNotificationItem: PropTypes.func,
};

export default App;
