import React, { useRef, useState, useEffect } from 'react';
import Expandable from '../expandable';
import PropTypes from 'prop-types';
import licenseForms from '../licensing/licenseMap';
/**
 * Renders an anchor element and simulates a click on it,
 * to allow instant downloads after data transfer from the
 * licensing service.
 * @param {object} props
 * @param {Blob} props.fileBlob - The Blob object (usually from an HTTP response) to create a download URL for
 * @param {Function} props.cb - An optional function to execute after the simulated mouse click
 * @returns -
 */
export const FileDownloader = ({ fileBlob, fileName, cb }) => {
    const [fileUrl] = useState(URL.createObjectURL(fileBlob));
    const downloadAnchorRef = useRef();

    // NOTE: We need to simulate a click on the link here to trigger the download.
    // element.click() is not exposed via refs, so we dispatch
    // events that constitute a mouse click.
    const simulateMouseClick = (element) => {
        const mouseClickEvents = ['mousedown', 'click', 'mouseup'];

        mouseClickEvents.forEach((mouseEventType) =>
            element.dispatchEvent(
                new MouseEvent(mouseEventType, {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1,
                })
            )
        );
    };

    useEffect(() => {
        simulateMouseClick(downloadAnchorRef.current);
        URL.revokeObjectURL(fileUrl);
        cb && cb();
    }, []);
    return (
        <a
            ref={ downloadAnchorRef }
            href={ fileUrl }
            download={ fileName || fileBlob.name || 'license.dat' }
            title="Download Link"
            data-testid="auto-downloader"> </a>
    );
};

FileDownloader.propTypes = {
    fileBlob: PropTypes.instanceOf(Blob),
    fileName: PropTypes.string,
    cb: PropTypes.func,
};

/**
 * Displays an expander with information to obtain a license.
 *
 * Displays a form or other interactive elements when licenses require user input.
 * @param {object} props
 * @param {object} props.lab - the Lab to which the license product is associated
 * @param {string} props.lab.id - The ID of the lab
 * @param {array}  props.licenseInstructions - An array containing lab licensing instructions
 * @returns A react element containing passed license HTML and related functionality
 */
const LicenseExpandable = ({
    licenseInstructions: {
        field_lab_instruction_display_na,
        field_lab_instruction,
    },
    title,
    labData,
}) => {
    const LicenseActionComponent =
        // eslint-disable-next-line max-len
        typeof licenseForms[field_lab_instruction_display_na] !== 'undefined' ? licenseForms[field_lab_instruction_display_na].component : null;
    const content = licenseForms[field_lab_instruction_display_na]?.content;

    return (
        <Expandable
            title={ title || `Licensing: ${field_lab_instruction_display_na}` }
            colorScheme="darkGrey">
            <div>
                <h5>{field_lab_instruction_display_na}</h5>
                {LicenseActionComponent &&
                    <LicenseActionComponent
                        labData={ labData }
                        text={ licenseForms[field_lab_instruction_display_na].text }
                        licensingIntegrationFn={ licenseForms[field_lab_instruction_display_na].licensingIntegrationFn }
                        content={ content || {} }
                    />}
                <div
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{ __html: field_lab_instruction }}
                />
            </div>
        </Expandable>
    );
};

LicenseExpandable.propTypes = {
    /** Abbreviated lab information */
    labData: PropTypes.object,
    /** An object containing lab licensing instructions */
    licenseInstructions: PropTypes.object,
    /** The title of the Licensing integration */
    title: PropTypes.string,
};
export default LicenseExpandable;
