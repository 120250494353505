/**
 * @prettier
 */
import {FeatureFlagDefinition} from '../types';
import featureConfig from '../featureConfig.json';
const {
    apiProxyVersion: {flagConfiguration, flagMetadata},
} = featureConfig;

const config: FeatureFlagDefinition = {
    configuration: flagConfiguration,
    metadata: flagMetadata,
};

export default config;
