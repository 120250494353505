import './contact-us.scss';

import React, { Component } from 'react';

//import { Form } from 'react-formio'; //removed until we get approved to use formio

import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as R from 'ramda';

import FontAwesome from 'react-fontawesome';

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            type: '',
            response: '',
            copy: false,
        };

        this.toggleModal = R.bind(this.toggleModal, this);
        this.formioSubmit = R.bind(this.formioSubmit, this);
        this.handleFormChanges = R.bind(this.handleFormChanges, this);
    }

    toggleModal() {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    handleFormChanges({ target }) {
        if (target.type === 'checkbox') {
            this.setState({
                [target.id]: target.checked ? 1 : 0,
            });
        } else {
            this.setState({
                [target.id]: target.value,
            });
        }
    }

    formioSubmit() {
        this.toggleModal();
    }

    render() {
        return window.contactus ? (
            <div className="contact-us">
                <div className="ask-a-question">
                    <Button variant="outline-primary" onClick={ this.toggleModal }>
                        <FontAwesome name='question-circle' /> &nbsp;
                        Ask a Question
                    </Button>
                </div>
                <Modal className="contact-us" show={ this.state.showModal } onHide={ this.toggleModal }>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Ask a Question
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* removed until we get permission to use formio
                        <Form src="https://aygvlhggyvoexbf.form.io/sansondemandaskaquestion"
                            onSubmit={ this.formioSubmit }
                            submission={ {
                                data: {
                                    userName: this.props.name,
                                    userID: this.props.sansAccountID,
                                    userEmail: this.props.sansEmail,
                                },
                            } }
                        />
                        */}
                    </Modal.Body>
                </Modal>
            </div>
        ) : null;
    }
}

ContactUs.propTypes = {
    defaultExpanded: PropTypes.bool,
    name: PropTypes.string,
    sansAccountID: PropTypes.string,
    sansEmail: PropTypes.string,
};

export default ContactUs;
