import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import './captionLabel.css';

/**
 * Provides a caption to the right of the dynamic range provisioning button regarding
 * meta information about ranges left to provision or available extensions.
 *
 * @param {object} props
 * @param {?import ('../../../../types/types').RangeInstance} props.range The active
 *  range for this button, if applicable.
 * @param {import ('../../../../types/types').RangeRule} props.rule Data about the range definition.
 * @param {import ('../../../../types/types').RangeInstance[]} props.ranges An array representing
 *  the entirety of the student's ranges.
 * @param {boolean} props.provisioned Whether the range has completed provisioning by the external
 *  polling process.
 * @returns {JSX.Element}
 */
const CaptionLabel = ({
    range,
    rule,
    ranges,
    provisioned,
}) => {
    let caption;

    if (typeof range !== 'undefined' && range?.status === 'fulfilled') {
        const EXTENSION_DURATION_IN_DAYS = 2;

        // Calculate remaining extensions...
        const startDate = moment(range.create_at);
        const maximumDate = moment(range.expires_at).add(1, 'hours');
        const diffInDays = maximumDate.diff(startDate, 'd');

        const remaining = Math.ceil((rule.maximum_duration_in_days - diffInDays) / EXTENSION_DURATION_IN_DAYS);
        const maximum = Math.ceil(
            (rule.maximum_duration_in_days - rule.default_duration_in_days) / EXTENSION_DURATION_IN_DAYS
        );

        if (remaining >= 0) {
            caption = (<div className="caption">
                <span className="emphasis">{remaining}/{maximum}</span> extensions remaining
            </div>);
        }
    } else if (rule.maximum_runs - ranges.length > 0 && !provisioned) {
        const maximumRuns = rule.maximum_runs;
        const remaining = maximumRuns - ranges.length;

        caption = (<div className="caption">
            <span className="emphasis">{remaining}/{maximumRuns}</span> launches remaining
        </div>);
    }

    return (
        <span>
            {rule.provisioning_duration_in_minutes > 0 &&
                (rule.maximum_runs - ranges.filter((r) => r.status !== 'pending').length > 0) &&
                !provisioned &&
                <div className="caption">
                    Estimated time to provision: {rule.provisioning_duration_in_minutes} minute
                    {rule.provisioning_duration_in_minutes === 1 ? '' : 's'}
                </div>}

            {caption}
        </span>
    );
};

CaptionLabel.propTypes = {
    /**
     * The object that holds the range provisioning request. This range may be empty (`null`)
     * or may have contain an object with `status` property.
     */
    range: PropTypes.object,

    /** The range provisioning definition. */
    rule: PropTypes.object,

    /** An array of ranges. */
    ranges: PropTypes.arrayOf(PropTypes.object),

    /** Whether the provisioning has been completed by the external polling process. */
    provisioned: PropTypes.bool,
};

export default CaptionLabel;
