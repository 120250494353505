/**
 * @prettier
 */
import {OpenFeature, InMemoryProvider} from '@openfeature/react-sdk';

import {
    FeatureFlagConfiguration,
    FeatureFlagDefinition,
    FeatureFlagMemoryStore,
} from './types';
import appConfig from '../appConfig';

export const featureFlagDomain = 'mylabs-features';

let flagMemoryStore: FeatureFlagMemoryStore = {};

/**
 * Set flag configuration in `flagMemoryStore`.
 *
 * This function is usually called via `setupFeatureFlags`, not by itself
 * (though, testing scenarios may require that we do call this on it's own).
 *
 * @summary Set flag configuration in `flagMemoryStore`.
 */
export const setMemoryStore = (
    /** Flags to store in memory */
    flagDefinitions: {
        [index: string]: FeatureFlagDefinition;
    }
) => {
    // Reduce our FeatureFlagDefinitions into a more accessible object
    // who's keys are proper flag names (ie, `files-api-version`) & values
    // are the full definition object
    Object.keys(flagDefinitions).forEach(flag => {
        const flagName = flagDefinitions[flag]?.metadata.flagName;
        const config = flagDefinitions[flag];
        if (flagName && config) {
            flagMemoryStore[flagName] = config;
        }
    })
};

/**
 * Extracts flag definitions into valid flag configuration, sets an in-memory provider,
 * and calls `setMemoryStore` to store the flag definitions in memory.
 */
export const setupFeatureFlags = async (
    flagDefinitions: {[index: string]: FeatureFlagDefinition} = {},
    /**
     * If true, use the awaited `setProviderAndWait` method to set the provider.
     *
     * __Ensure the `Promise` is awaited or resolved if this is set to `true`.__
     * */
    awaitProvider?: boolean
) => {
    // Extract flag configuration into a format to pas into the provider
    const flagConfiguration = Object.values(flagDefinitions).reduce<{
        [x: string]: FeatureFlagConfiguration;
    }>((acc, {metadata: {flagName}, configuration: fc}) => {
        acc[flagName] = fc;
        return acc;
    }, {});
    const featureFlagProvider = new InMemoryProvider(flagConfiguration);
    if (awaitProvider) {
        await OpenFeature.setProviderAndWait(
            featureFlagDomain,
            featureFlagProvider
        );
    } else {
        OpenFeature.setProvider(featureFlagDomain, featureFlagProvider);
    }

    setMemoryStore(flagDefinitions);
    if (appConfig().runtimeEnv === 'local'){
        console.debug('Feature flags set up with definitions:', flagMemoryStore);
    }
};

/**
 * Clear the memory store of all flag definitions.
 */
export const clearMemoryStore = () => {
    flagMemoryStore = {};
    OpenFeature.clearProviders();
};

/**
 * Clears OpenFeature Providers and resets the memory store.
 *
 * __This function is intended for story/test usage and should not be used at runtime.__
 *
 * @summary Reset OpenFeature & memory store.
 */
export const clearFeatureFlags = async () => {
    await OpenFeature.clearProviders();
    clearMemoryStore();
};

/**
 * Sets a forwarded value for a flag.
 */
export const setForwardedValue = (flagName: string, value: string) => {
    if (!flagMemoryStore[flagName]) {
        console.warn(`Flag ${flagName} not found in memory store. Flag may require client implementation.`);
    } else {
        flagMemoryStore[flagName]!.forwardedValue = value;
        flagMemoryStore[flagName]!.configuration.defaultVariant = value;
        if (appConfig().runtimeEnv === 'local' || appConfig().runtimeEnv === 'test'){
            console.debug(`Flag ${flagName} set to ${value} via client forwarding`);
        }
    }
};

export const getFlagMemoryStore = () => flagMemoryStore;
