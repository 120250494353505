import React, { useEffect, useState } from 'react';
import { getIDAProLicense } from '../../../utils/api-licensing';
import LicenseError from '../license-error/license-error';
import PropTypes from 'prop-types';
import './idapro.scss';

/* eslint-disable max-len */

const options = [
    'Windows',
    'Mac',
    'Linux',
    'Opt-Out (I will provide my own IDA 7.4+ license)',
];

const IdaPro = ({ labData }) => {
    const [userAgent] = useState(window.navigator.userAgent);
    const resolveInitialPlatformOption = () => {
        // Map user agent to an option...
        if (userAgent.match(/Macintosh/) !== null) {
            return 'Mac';
        }
        if (userAgent.match(/Linux/) !== null) {
            return 'Linux';
        }

        // Default option...
        return 'Windows';
    };

    const [licenseRequested, setLicenseRequested] = useState('');
    const [licenseError, setLicenseError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [platform, setPlatform] = useState(resolveInitialPlatformOption());

    useEffect(() => {
        resolveInitialPlatformOption();
    });

    const handleSelect = (e) => {
        setPlatform(e.target.value);
    };

    const getLicense = async () => {
        setIsSubmitting(true);
        setLicenseError(null);

        let req;

        try {
            req = await getIDAProLicense(platform, labData);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Failed requesting the IDA Pro license: ', e);
        }

        if (typeof req.error !== 'undefined' && req.error !== '') {
            setLicenseError('Please ensure you are connected to the Internet and try again.');
        } else {
            setLicenseRequested(true);
        }

        setIsSubmitting(false);
    };

    const RequestLicenseButton = () => {
        return (
            <div>
                <label>
            Choose your platform:
                    <select
                        className="idapro-platform"
                        onChange={ handleSelect }
                        value={ platform }
                        disabled={ licenseRequested || isSubmitting }
                    >
                        {options.map((opt, i) => (
                            <option key={ i } value={ opt }>
                                {opt}
                            </option>
                        ))}
                    </select>
                </label>
                <button
                    className="idapro-licensing-submit-btn"
                    disabled={ licenseRequested || isSubmitting }
                    onClick={ () => getLicense() }
                >
            Register for IDA Pro
                </button>
            </div>
        );
    };

    const SuccessMessage = () => {
        return (
            <p className="idapro-success-message">
                { platform.includes('Opt-Out') && 'Thank you for registering. '}
                { !platform.includes('Opt-Out') && `Thank you for requesting your license for ${platform}. You will receive an email directly from Hex Rays with your license key. Please allow for up to 48 hours to hear from Hex Rays.` }
            </p>
        );
    };

    return (
        <section>
            { licenseError && <LicenseError error={ licenseError } altResourceName="IDA Pro license" /> }
            { isSubmitting && <div className="licensing-submit-btn__download-spinner" data-testid="download-spinner" /> }
            { licenseRequested && <SuccessMessage /> }
            { !licenseRequested && <RequestLicenseButton /> }
        </section>
    );
};

IdaPro.propTypes = {
    labData: PropTypes.object,
};
export default IdaPro;
