import React, { useState } from 'react';
import { getArsenalLicense } from '../../../utils/api-licensing';
import LicenseDisplayBasic from '../license-display/license-display-basic';
import LicenseError from '../license-error/license-error';
import LicenseFormSubmitButton from '../license-form-submit-button/license-form-submit-button';
import PropTypes from 'prop-types';
import '../license-form.scss';

const Arsenal = ({ labData }) => {
    const [license, setLicense] = useState(null);
    const [licenseError, setLicenseError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getLicense = async () => {
        try {
            setIsSubmitting(true);
            const licenseResponse = await getArsenalLicense(labData);

            if (licenseResponse.license) {
                setLicense(licenseResponse.license);
                setLicenseError(null);
            } else {
                setLicenseError(licenseResponse.error);
                setLicense(null);
            }
        } catch (e) {
            setLicenseError('An error has occured while trying to retrieve your Arsenal license.');
            // eslint-disable-next-line no-console
            console.log(e);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <section className="license-form">
            <LicenseFormSubmitButton onClick={ () => getLicense() }
                disabled={ isSubmitting }
                submitting={ isSubmitting }>Get Arsenal License</LicenseFormSubmitButton>
            {license && <LicenseDisplayBasic license={ license } />}
            {licenseError && <LicenseError error={ licenseError } />}
        </section>
    );
};

Arsenal.propTypes = {
    labData: PropTypes.object,
};

export default Arsenal;
