import { AnyAction, ThunkDispatch, combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { routerReducer } from 'react-router-redux';
import labReducer from '../features/labSlice';
import accountReducer from '../features/accountSlice';
import rangeProvisioningReducer from '../features/rangeProvisioningSlice';
import filesReducer from '../features/filesSlice';
import configReducer from '../features/configSlice';
import { AppConfiguration } from '../config/appConfig';

const rootReducer = combineReducers({
    accountReducer,
    labReducer,
    rangeProvisioningReducer,
    routing: routerReducer,
    filesReducer,
    configReducer,
});

export const setupStore = (preloadedState: { configReducer: { config: AppConfiguration; }; }) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
// redux hooks using the above explicit types
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
