import './expandable.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';

import * as R from 'ramda';

import FontAwesome from 'react-fontawesome';

class Expandable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: !!props.defaultExpanded,
        };

        this.toggle = R.bind(this.toggle, this);
    }

    toggle() {
        this.setState({
            expanded: !this.state.expanded,
        });
    }

    render() {
        const { title, subTitle, children, colorScheme } = this.props;

        return (
            <Card
                className={ `expandable  expandable__${colorScheme}` }
            >
                <Card.Header
                    className="title"
                >
                    <span onClick={ this.toggle } className="expandable-target">
                        <FontAwesome name={ this.state.expanded ? 'minus' : 'plus' } className="expandable-icon" />
                        { title }
                    </span>
                    { subTitle }
                </Card.Header>
                <Collapse in={ this.state.expanded }>
                    <Card.Body>
                        {
                            typeof children === 'string' ?
                                (
                                    <Card.Text>
                                        { children }
                                    </Card.Text>
                                ) : (
                                    <React.Fragment>
                                        { children }
                                    </React.Fragment>
                                )
                        }
                    </Card.Body>
                </Collapse>
            </Card>
        );
    }
}

Expandable.propTypes = {
    /** Whether or not the expandable is opened by default */
    defaultExpanded: PropTypes.bool,
    /** The main title of the expandable */
    title: PropTypes.string,
    /** The subtitle, appearing on the far right, of the expandable bar */
    subTitle: PropTypes.object,
    /** The inner content of the expandable */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
    ]),
    /** The colorScheme of the expandable */
    colorScheme: PropTypes.string,
};

Expandable.defaultProps = {
    colorScheme: 'dark6',
};

export default Expandable;
