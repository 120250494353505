import { http, HttpResponse } from 'msw';

export const createMockAccountResponseHandler = (responseData) => {
    return http.get(
        'http://localhost:8200/api/account_details',
        () => {
            return HttpResponse.json(responseData);
        }
    );
};

export const createMockUsersResponseHandler = (responseData) => {
    return http.get('http://localhost:8200/api/users', () => {
        return HttpResponse.json(responseData);
    });
};

export const createMockFilesV1ResponseHandler = (
    courseCatalog,
    filesResponse
) => {
    http.post('http://localhost:8200/api/files', ({ params }) => {
        const { course } = params;

        if (course === courseCatalog) {
            return HttpResponse.json(filesResponse);
        }

        return HttpResponse.json([]);
    });
};

