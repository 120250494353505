import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import './statusLabel.css';
import { simpleAlert, simpleConfirm } from 'react-simple-dialogs';
import { REGION_DISPLAY_NAMES } from './constants';

/**
 * Renders a status label describing the current state of the dynamically-provisioned
 * range.
 *
 * @param {object} props
 * @param {import ('../../../../types/types').RangeInstance} props.range Data about the range.
 * @param {function } props.handleDeleteClick The function to call when the user clicks the delete button.
 *
 * @returns {JSX.Element}
 */
const StatusLabel = ({
    range,
    handleDeleteClick,
}) => {
    if (range?.status === 'fulfilled' && new Date(range?.expires_at) > new Date()) {
        const status = 'Range Active';
        const expirationDate = moment(range.expires_at).format('MMMM Do, YYYY h:mm:ss a');
        const provisionedSnippet =
            <span key="region"><code>{range.aws_region}</code> ({REGION_DISPLAY_NAMES[range.aws_region]})</span>;

        const handleClick = async () => {
            if (new Date() > new Date(range.expires_at)) {
                await simpleAlert(
                    'The range has already expired and cannot be deleted.'
                );
                await handleDeleteClick();
            } else {
                if (await simpleConfirm({
                    title: 'DELETE RANGE',
                    message: 'Are you sure you want to delete this range?',
                    confirmLabel: 'Delete',
                    cancelLabel: 'Cancel',
                })) {
                    await handleDeleteClick();
                }
            }
        };

        const deleteSnippet = <button data-testid="delete-btn" onClick={ handleClick }>Delete range</button>;

        const expirationCaption = <span className="caption">
            Your range {range.aws_region !== null && range.aws_region !== '' ?
                ['is provisioned in ', provisionedSnippet, ' and '] :
                ''} expires {expirationDate}.
            { deleteSnippet }
        </span>;

        return (
            <div className="status-label">
                <span className="label success">{status}</span>
                {expirationCaption}
            </div>
        );
    } else if (range?.status === 'completed' || new Date(range?.expires_at) < new Date()) {
        const status = 'Range Inactive';
        const expirationDate = moment(range.expires_at).format('MMMM Do, YYYY h:mm:ss a');
        const provisionedSnippet =
            <span key="region"><code>{range.aws_region}</code> ({REGION_DISPLAY_NAMES[range.aws_region]})</span>;

        const expirationCaption = <span className="caption">
            Your last range {range.aws_region !== null && range.aws_region !== '' ?
                ['was provisioned in ', provisionedSnippet, ' and '] :
                ''} expired at {expirationDate}.
        </span>;

        return (
            <div className="status-label">
                <span className="label">{status}</span>
                {expirationCaption}
            </div>
        );
    }
    return (<div />);
};

StatusLabel.propTypes = {
    /**
     * The object that holds the range provisioning request. This range may be empty (`null`)
     * or may have contain an object with `status` property.
     */
    range: PropTypes.object,

    /**
     * The function to call when the user clicks the delete button.
     */
    handleDeleteClick: PropTypes.func,
};

export default StatusLabel;
