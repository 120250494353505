import {
    handledGetFetch,
} from '../../../submodules/shared/utils/api-base';

import {
    API_BASE,
    ACCOUNT_DETAILS_PATH,
    OVPN_PATH,
} from '../../../constants';

export const getAccountDetails = () =>
    handledGetFetch(`${ API_BASE }/${ ACCOUNT_DETAILS_PATH }`);

export const getOVPNCert = (labId, os, userProductCacheId) =>
    handledGetFetch(`${API_BASE}/${OVPN_PATH}/${labId}/${userProductCacheId}?os=${os}`);
