/**
 * Create an int ID. This can be used for different ID types used within a lab (ie, id, event_id, event_product_id)
 * @param {number} [value=123] - The integer to use as the value for the ID
 * @returns {number}
 */
const generateFakeLabId = (value = 123) => value;

/**
 * Generate or supply a course catalog
 * @param {string} [value]
 * @returns {string}
 */
const generateFakeCourseCatalog = (value = 'MAU100') => value;

/**
 * Generate or supply a courseware version
 * @param {string} value
 * @returns {string}
 */
const generateFakeCoursewareVersion = (value = 'A01') => value;

/**
 * Creates a lab name including course instance, ie: ABC123-A01-GEN
 * @param {Object} options
 * @param {string} [options.catalog]
 * @param {string} [options.version ]
 * @param {string} [options.modality]
 * @returns {string}
 */
const generateFakeLabName = (options = {}) => {
    const catalog = generateFakeCourseCatalog(options.catalog);
    const version = generateFakeCoursewareVersion(options.version);
    const modality = options.modality === 'OnDemand' ? 'GEN' : 'LIVE';

    return `${catalog}-${version}-${modality}`;
};

/**
 * Generate or supply a course modality
 * @param {string} value
 * @returns {string}
 */
const generateFakeCourseModality = (value = 'OnDemand') => value;

// eslint-disable-next-line valid-jsdoc
/**
 * Generate a course name based on course catalog and optional title
 * @param {Object} options
 * @param {string} options.courseCatalog
 * @param {string} [options.title]
 */
const generateFakeLabCourseName = (options) => {
    if (!options) {
        options = {};
    }
    return `${generateFakeCourseCatalog(options.courseCatalog)}: ${options.title || 'A test course'}`;
};

/**
 *
 * @param {string} type
 * @param {number} id
 * @param {string} instructionHtml
 * @param {string} displayName
 * @param {string} title
 * @returns {Object}
 */
const generateFakeLabInstruction = (type, id, instructionHtml, displayName, title) => {
    return {
        field_instruction_type: type,
        field_lab_id: id,
        field_lab_instruction: instructionHtml,
        field_lab_instruction_display_na: displayName,
        title,
    };
};
/**
 * Generate a set of lab instructions for the lab
 * @param {number} labId
 * @param {Array} instructions
 * @param {string} labName
 * @returns {Array}
 */
const generateFakeLabInstructions = (labId, instructions, labName) => {
    if (!instructions) {
        return [generateFakeLabInstruction(
            'Overview', labId, '<p>This is a local test lab</p>', 'Test Lab Intro', labName)];
    }

    return instructions.map((instruction) =>
        generateFakeLabInstruction(
            instruction.type,
            instruction.id,
            instruction.instructionHtml,
            instruction.displayName,
            instruction.title)
    );
};

/**
 * Generate a lab section, 0 or 1. Defaults to 1.
 * @param {number} [value]
 * @returns {string}
 */
const generateFakeLabSection = (value = '1') => value.toString();

/**
 * Generate a lab type, defaults to HTTP_AUTH
 * @param {string} [value]
 * @returns {string}
 */
const generateFakeLabType = (value = 'HTTP_AUTH') => value;

/**
 * Generate a lab series, defaults to null.
 * @param {*} value
 * @returns {*}
 */
const generateFakeLabSeries = (value = null) => value;

// eslint-disable-next-line valid-jsdoc
/**
 * Generates a lab expiration date based on a start date and modality
 * @param {Date} startDate
 * @param {string} modality
 */
const generateFakeLabExpiration = (startDate, modality) => {
    const labStartDate = new Date(startDate);

    if (modality === 'OnDemand') {
        labStartDate.setMonth(labStartDate.getMonth() + 4);
    } else {
        labStartDate.setDate(labStartDate.getDate() + 14);
    }

    return `${labStartDate.toISOString().substring(0, 10)} ${labStartDate.toISOString().substring(11, 22)}`;
};

/**
 * Fakelab: Better Lab Mocking for MyLabs
 */

const createInstruction = ({
    field_lab_id,
    title,
    field_instruction_type,
    field_lab_instruction_display_na,
    field_lab_instruction,
}) => {
    return {
        field_lab_id,
        title,
        field_instruction_type,
        field_lab_instruction_display_na,
        field_lab_instruction,
    };
};

/**
 *
 * @param {Object} options
 * @param {string} [options.courseCatalog]
 * @param {string} [options.coursewareVersion]
 * @param {string} [options.modality]
 * @param {string} [options.courseName]
 * @param {number} [options.labId]
 * @param {number} [options.labEventId]
 * @param {number} [options.labEventProductId]
 * @param {number} [options.labProductId]
 * @param {Date|string} [options.labStartDate]
 * @param {string} [options.labType]
 * @param {string} [options.labSection]
 * @param {string} [options.labSeries]
 * @returns {Lab}
 */
export const generateFakelab = (options) => {
    if (!options) {
        options = {};
    }
    const courseCatalog = generateFakeCourseCatalog(options.courseCatalog);
    const coursewareVersion = generateFakeCoursewareVersion(options.coursewareVersion);
    const courseModality = generateFakeCourseModality(options.modality);
    const courseName = generateFakeLabCourseName(courseCatalog, options.courseName);

    const labNameOpts = {
        catalog: courseCatalog,
        version: coursewareVersion,
        modality: courseModality,
    };
    const labName = generateFakeLabName(labNameOpts);
    const labId = generateFakeLabId(options.labId || 123);
    const labEventId = generateFakeLabId(options.labEventId || 456);
    const labEventProductId = generateFakeLabId(options.labEventProductId || 78901);
    const labProductId = generateFakeLabId(options.labProductId);

    const labStartDateString = '2020-01-06T00:00:00';
    const labStartDate = new Date(options.labStartDate || labStartDateString);
    const labStartDateUtc = labStartDate.toISOString();
    const labStart = `${labStartDateUtc.substring(0, 10)}`;
    const labUtcStart = labStartDateUtc;
    const labExpiration = generateFakeLabExpiration(labStartDate, courseModality);

    const labType = generateFakeLabType(options.labType);
    const labSection = generateFakeLabSection(options.labSection);
    const labSeries = generateFakeLabSeries(options.labSeries);
    const labStartException = null;
    const labSharedEnvironments = null;
    const labTimezoneAbbreviation = null;
    const userProductCacheId = 1234567;

    const labOVPNLinux = null;
    const labOVPNWindows = null;

    // eslint-disable-next-line no-undefined
    const labInstructions = generateFakeLabInstructions(labId, undefined, labName);

    return {
        course_name: courseName,
        event_id: labEventId,
        event_product_id: labEventProductId,
        expiration_date: labExpiration,
        id: labId,
        instructions: labInstructions,
        lab_2_start_exception: labStartException,
        lab_series: labSeries,
        lab_start: labStart,
        name: labName,
        ovpn_linux: labOVPNLinux,
        ovpn_windows: labOVPNWindows,
        product_id: labProductId,
        section: labSection,
        shared_environment: labSharedEnvironments,
        timezone_abbreviation: labTimezoneAbbreviation,
        type: labType,
        utc_lab_start: labUtcStart,
        user_product_cache_id: userProductCacheId,
    };
};

// createLab spits out mocked labs, as would be passed into renderLab
export const createLab = ({
    courseName,
    courseCatalog,
    labId,
    labType,
    labSection,
    labStart,
    productId,
    eventId,
    eventProductId,
    customInstructions,
}) => {
    const fakelabOverview = customInstructions?.Overview || createInstruction({
        field_lab_id: labId,
        courseName,
        field_instruction_type: 'Overview',
        field_lab_instruction_display_na: 'Mocked Lab Overview',
        field_lab_instruction: `<p>This lab was mocked using fakelab utils.</p>`,
    });
    const Overview = [fakelabOverview];

    // fill in the other sections
    // custom instructions is a js object
    /**
     * {
     *      Access:{[
     *          instructionHtml: '<p>Access</p>',
     *          displayName: 'Register for Netwars acct']
     *
     *      }
     * }
     */

    const instructionsKeys = Object.keys(customInstructions);

    const finalInstructions = {
        Overview,
    };
    // for each key in customInstructions
    // add the key to the finalInstructions and instatiate an array
    // loop through each element in the key matching the key we're iterating on

    instructionsKeys.forEach((key) => {
        const sectionInstructions = customInstructions[key].map(
            (customInstruction) =>
                createInstruction({
                    field_lab_id: labId,
                    courseName,
                    field_instruction_type: key,
                    field_lab_instruction_display_na:
                        customInstruction.displayName,
                    field_lab_instruction: customInstruction.instructionHtml,
                })
        );

        finalInstructions[key] = sectionInstructions;
    });

    return {
        instructions: finalInstructions,
        id: labId,
        name: courseCatalog,
        product_id: productId || 1,
        section: labSection || 6,
        ovpn_windows: null,
        ovpn_linux: null,
        type: labType || 'HTTP_AUTH',
        lab_series: null,
        shared_environment: null,
        course_name: courseName,
        event_id: eventId,
        event_product_id: eventProductId || null,
        lab_start: labStart || '2021-10-05',
        utc_lab_start: '2021-10-05 17:26:53',
        user_product_cache_id: 3788995,
        timezone_abbreviation: null,
        expiration_date: '2022-02-05 00:00:00',
        lab_2_start_exception: null,
        mockedLab: true,
    };
};
