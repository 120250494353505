import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line valid-jsdoc
/**
 * Displays simple text licenses, with a value that can be
 * clicked to copy its value to the clipboard
 * @param {*} param0
 * @returns
 */
const LicenseDisplayBasic = ({ license, licenseText }) => {
    const copyLicenseToClipboard = async () => {
        await navigator.clipboard.writeText(license);
    };

    return (
        <section className="licensing-display">
            <span className="licensing-display__descriptive-text">
                {licenseText ? licenseText : 'Your license is: '}
            </span>
            <span
                className="licensing-display__license"
                onClick={ () => copyLicenseToClipboard() }
            >
                {license}
            </span>
        </section>
    );
};

LicenseDisplayBasic.propTypes = {
    license: PropTypes.string.isRequired,
    licenseText: PropTypes.string,
};
export default LicenseDisplayBasic;
