import iFetch from 'isomorphic-fetch';
import * as R from 'ramda';
import { API_CREDENTIALS } from '../../../constants';
import { handleError } from './handle-error';
import { simpleAlert } from 'react-simple-dialogs';

const getConfig = (method) => ({
    credentials: API_CREDENTIALS,
    method,
    headers: {
        Accept: 'application/json',
        Cookie: document.cookie,
        'Content-Type': 'application/json',
    },
});

if (!window.fetch) {
    window.fetch = iFetch;
}
/**
 * default for handling api responses
 * @param  {object} response
 * @return {object} response.json()
 */
const handleResponse = (response) => {
    if (response.status === 409) {
        response.json()
            .then((data) => {
                if (data.success === false && data.error === 'Please refresh the page and try again.') {
                    simpleAlert('Session is stale, refreshing the page...');
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                }
            })
            .catch(handleError);
    }

    if (response.status === 401) {
        response.json()
            .then((data) => {
                const redir = data.redirect_url ? data.redirect_url + '?url=' + window.location.href : 'https://www.sans.org/';

                window.location.assign(redir);
            })
            .catch(handleError);
    } else if (!response.ok) {
        handleError(response.statusText);
    }
    return response.json();
};

/**
  * @private
  * @name getFetch
  * @description Initiates a GET fetch request using default options
  * @param  {string}  url The URL to GET
  * @return {Promise}     A fetch Promise
  */
const getFetch = (url) =>
    fetch(url, getConfig('GET'));

export const handledGetFetch = (url) =>
    getFetch(url)
        .then(handleResponse); //.catch(handleError); //This is done in handle response !ok

/**
  * @private
  * @name postFetch
  * @description Initiates a POST fetch request using default options
  * @param  {string} url  The URL to POST to
  * @param  {object} body The POST body to send
  * @return {Promise}     A fetch Promise
  */
const postFetch = (url, body) =>
    fetch(url, R.assoc('body', JSON.stringify(body), getConfig('POST')));

export const handledPostFetch = (url, body) =>
    postFetch(url, body)
        .then(handleResponse); //.catch(handleError); //This is done in handle response !ok

/**
  * @private
  * @name putFetch
  * @description Initiates a POST fetch request using default options
  * @param  {string} url  The URL to POST to
  * @param  {object} body The POST body to send
  * @return {Promise}     A fetch Promise
  */
const putFetch = (url, body) =>
    fetch(url, R.assoc('body', JSON.stringify(body), getConfig('PUT')));

export const handledPutFetch = (url, body) =>
    putFetch(url, body)
        .then(handleResponse); //.catch(handleError); //This is done in handle response !ok
