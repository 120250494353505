import React from 'react';
import PropTypes from 'prop-types';
const LicenseError = ({ error, altResourceName }) => {
    return (
        <section className="licensing-error-display">
            <p className="licensing-error-display__warning">
                An error has occurred while attempting to retrieve your {altResourceName || 'license'}.
            </p>
            <p className="licensing-error-display__message">{error}</p>
        </section>
    );
};

LicenseError.propTypes = {
    error: PropTypes.string,
    altResourceName: PropTypes.string,
};

export default LicenseError;
