const localhost = 'http://localhost:8200';

/* eslint-disable no-undef */

const executingLocally = window.location.hostname.indexOf('localhost') === 0;
const executingTesting = window.location.hostname.indexOf('dev-cmp') === 0 ||
    window.location.hostname.indexOf('dev-mylabs.sans.org') === 0 ||
    window.location.hostname.indexOf('qa-mylabs.sans.org') === 0;

export const API_BASE = (executingLocally ? localhost : '') + '/api';
export const API_CREDENTIALS = 'include';

export const LICENSING_BASE = executingLocally || executingTesting ?
    process.env.REACT_APP_SANS_LICENSING_API_SANDBOX :
    process.env.REACT_APP_SANS_LICENSING_API_PRODUCTION;

export const FILES_API_BASE =
    // eslint-disable-next-line no-nested-ternary
    executingLocally ? process.env.REACT_APP_MYLABS_FILES_API_LOCAL :
        executingTesting ? process.env.REACT_APP_MYLABS_FILES_API_SANDBOX :
            process.env.REACT_APP_MYLABS_FILES_API_PRODUCTION;
