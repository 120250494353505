import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './license-file-upload.scss';

/* eslint-disable max-len */

/**
 * This callback executed when a user selects a file
 *
 * @callback changeCallback
 * @param {Object} uploadFile - the file being uploaded by the user
 */

/**
 * @typedef LicenseFileUploadProps
 * @property {string} inputLabel - The label text to show with the upload element
 * @property {string} inputAccepts - The types of files/extensions allowed by the uploader
 * @property {string} name - the name used by the `name` attribute of the element
 * @property {changeCallback} cb - The callback to fire when a user adds a file
 * @returns {JSX.Element}
 */

/**
 * Renders an input that takes a file. The file can be returned to parent
 * components via the callback.
 * 
 * @param {LicenseFileUploadProps} props
 * 
 * @returns {JSX.Element}
 */
const LicenseFileUpload = ({
    inputLabel,
    inputAccepts,
    inputId,
    showAllowedTypes,
    name,
    multiple,
    cb,
}) => {
    // eslint-disable-next-line no-unused-vars
    const [files, setFiles] = useState(null);

    const handleOnChange = (event) => {
        if (multiple === true) {
            const uploadFiles = event.currentTarget.files;

            setFiles(uploadFiles);
            return cb && cb(uploadFiles);
        }

        const uploadFile = event.currentTarget.files[0];

        setFiles(uploadFile);
        return cb && cb(uploadFile);
    };

    return (
        <section className="licensing-file-upload">
            {inputLabel && <label className="licensing-file-upload--label" htmlFor={ name }>{inputLabel}</label>}
            <input
                type="file"
                data-testid="license-file"
                id={ inputId || name }
                name={ name }
                accept={ inputAccepts || '' }
                multiple={ multiple }
                onChange={ handleOnChange }
            />
            {inputAccepts && showAllowedTypes && <p className="licensing-file-upload--allowed-types"><em>Allowed file types: <strong>{inputAccepts}</strong></em></p>}
        </section>
    );
};

LicenseFileUpload.propTypes = {
    inputLabel: PropTypes.string,
    name: PropTypes.string,
    inputAccepts: PropTypes.string,
    showAllowedTypes: PropTypes.bool,
    inputId: PropTypes.string,
    multiple: PropTypes.bool,
    cb: PropTypes.func,
};
export default LicenseFileUpload;
