import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { requestCloudEnvironmentByProvider } from '../../utils/api-licensing';

/**
 * A component for a cloud provider button
 *
 * @component
 * @param {Object} props
 * @param {string} props.provider - The name of the cloud provider
 *
 * @returns {JSX.Element}
 */
const CloudProviderButton = ({ provider, labData, isDisabled }) => {
    const [clicked, setClicked] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const isButtonDisabled = () => {
        // TODO: Add more logic around when this btn is disabled
        return clicked;
    };

    useEffect(() => {
        const buttonDisabled = isButtonDisabled();

        setDisabled(buttonDisabled);
    });

    // XXX: eslint cannot parse the following line, should be looked into @JedaiAtSans
    const onClick = async (e) => {
        setClicked(true);
        setSubmitting(true);
        try {
            e.preventDefault();

            await requestCloudEnvironmentByProvider(
                provider,
                'sec540',
                labData
            );
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log('An error occured: ', err.message);
        } finally {
            setDisabled(true);
            setSubmitting(false);
        }
    };

    /* eslint-disable react/jsx-curly-spacing */
    return (
        <button
            className="sans-btn"
            onClick={onClick}
            disabled={disabled || isDisabled}
        >
            {submitting ? (
                <div
                    className="sans-btn__download-spinner"
                    data-testid="download-spinner"
                />
            ) : (
                <React.Fragment>Request {provider}</React.Fragment>
            )}
        </button>
    );
    /* eslint-enable react/jsx-curly-spacing */
};

CloudProviderButton.propTypes = {
    /** The name of the cloud provider or other text this button activates */
    provider: PropTypes.string.isRequired,
    /** Abbreviated data from `lab` object */
    labData: PropTypes.object,
    /** Whether or not the button is disabled from an external source */
    isDisabled: PropTypes.bool,
};

export default CloudProviderButton;
