/**
 * Create range data for testing
 * @param {{
 *  mylabsPart?: string
 *  accountId?: number
 *  status?: string
 *  createdAt?: string
 *  id?: number
 * }} [options]
 */
export const createRangeData = ({
    mylabsPart = 'cse123-i01-gen',
    accountId = 12345678,
    status = 'pending',
    createdAt = new Date(Date.now()).toISOString(),
    id = 1000,
} = {}) => {
    const catalog = mylabsPart.slice(0, 6).toUpperCase();

    const version = mylabsPart.replace(/-gen|-live/, '01');
    const rangeLifeDays = 5;
    const expirationDate = new Date(createdAt);

    expirationDate.setDate(expirationDate.getDate() + rangeLifeDays);

    return {
        id,
        product_version: version,
        mylabs_part: mylabsPart,
        account_id: accountId.toString(),
        range_type: 'labs',
        expires_at: expirationDate.toISOString(),
        status,
        status_at: createdAt,
        attributes: null,
        created_at: createdAt,
        updated_at: createdAt,
        deleted_at: null,
        create_at: createdAt,
        product_catalog: catalog,
        aws_region: null,
    };
};

export const createRangeRule = (lab, {
    accountId = null,
    attributes = null,
    id = 1,
    createdAt = '2023-01-03T14:24:22+00:00',
    defaultDurationInDays = 14,
    extensionDurationInDays = 14,
    maximumDurationInDays = 28,
    maximumRuns = 5,
    provisioningDurationInMinutes = 5,
    rangeType = 'labs_aws',
    productVersionNumStr = '01',
    updatedAt = '2023-10-12T14:53:22+00:00',
} = {}) => {
    const labNameParts = lab.name.split('-');

    const mylabsPart = `${lab.testExtras.courseCstalog}-${labNameParts[1]}-${labNameParts[2]}`
        .toLowerCase();

    const productVersion = `${lab.testExtras.courseCstalog}-${labNameParts[1]}-${productVersionNumStr}`
        .toLowerCase();

    return {
        account_id: accountId,
        attributes,
        createdAt,
        default_duration_in_days: defaultDurationInDays,
        extension_duration_in_days: extensionDurationInDays,
        id,
        maximum_duration_in_days: maximumDurationInDays,
        maximum_runs: maximumRuns,
        mylabs_part: mylabsPart,
        product_version: productVersion,
        provisioning_duration_in_minutes: provisioningDurationInMinutes,
        range_type: rangeType,
        updated_at: updatedAt,
    }
}
