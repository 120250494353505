const REGION_DISPLAY_NAMES: { [key: string]: string } = {
    'us-east-1': 'N. Virginia',
    'us-east-2': 'Ohio',
    'us-west-1': 'N. California',
    'us-west-2': 'Oregon',
    'us-gov-west-1': 'GovCloud West',
    'us-gov-east-1': 'GovCloud East',
    'ca-central-1': 'Canada',
    'eu-north-1': 'Stockholm',
    'eu-west-1': 'Ireland',
    'eu-west-2': 'London',
    'eu-west-3': 'Paris',
    'eu-central-1': 'Frankfurt',
    'eu-south-1': 'Milan',
    'af-south-1': 'Cape Town',
    'ap-northeast-1': 'Tokyo',
    'ap-northeast-2': 'Seoul',
    'ap-northeast-3': 'Osaka',
    'ap-southeast-1': 'Singapore',
    'ap-southeast-2': 'Sydney',
    'ap-southeast-3': 'Jakarta',
    'ap-east-1': 'Hong Kong',
    'ap-south-1': 'Mumbai',
    'sa-east-1': 'São Paulo',
    'me-south-1': 'Bahrain',
    'cn-north-1': 'Beijing',
    'cn-northwest-1': 'Ningxia',
};

export { REGION_DISPLAY_NAMES };
