/**
 * This module contains functions for interacting with the MyLabs Files API.
 */

import {API_BASE} from '../constants-base';
import axios from 'axios';
import {isLabOD} from './labs';
import AppConfiguration from '../../../config/appConfig';
import {LabProduct, RangeInstance} from '../../../types/types';

const appConfig = AppConfiguration();

/**
 * Student File Metadata returned from the MyLabs Files API.
 */
export interface StudentFileMetadata {
    id: string;
    filename: string;
    description: string;
    'content-type': string;
    'content-length': number;
    'content-disposition': 'inline' | 'attachment';
    scope: 'course' | 'instance' | 'student';
    courseID: string;
    instanceID: string;
    studentID: string;
    token: string;
}

/** Version 1 payload for requesting student files */
export interface StudentFileRequestPayloadV1 {
    courseInstances: string[];
}

/** Response structure for V1 student file requests */
export interface StudentFileResponsePayloadV1 {
    [index: string]: StudentFileMetadata[];
}

/** Version 2 payload for requesting student files */
export interface MyLabsFilesRequestPayload {
    [x: string]: FilesAPIRequestPayload;
}

/** Version 2 file response */
export interface FilesResponse {
    eventProductID?: number | null;
    labID?: number | null;
    labName: string;
    instance: string;
    metadata: StudentFileMetadata[];
}

/** Bass properties for file requests */
interface FileRequestPayloadBase {
    type: 'file' | 'range';
    labName: string;
    instance: string | number;
}

export interface RangeFileRequestPayloadSection extends FileRequestPayloadBase {
    productCatalog: string;
    rangeID: number;
}

export interface StudentFileRequestPayloadSection
    extends FileRequestPayloadBase {
    labID: number;
    eventProductID: number | null;
}

export interface FilesAPIRequestPayload {
    [key: string]: (
        | RangeFileRequestPayloadSection
        | StudentFileRequestPayloadSection
    )[];
}

/**
 * Create a URL to retreive file metadata. The scope of the request is based upon the presence of eventProductId
 * and studentId. See https://github.com/sans-software-engineering/mylabs-files-api#api
 */
export const getURL = (
    /** the course catalog name (ie, SEC540) */
    course: string,
    /** the instance ID */
    instance: string,
    /** The student ID */
    student: string
) => {
    const baseUrl = `${appConfig.services.filesAPI.baseUrl}/`;
    const coursePathSegment = course ? `course/${course}/` : '';
    const courseInstancePathSegment =
        coursePathSegment && instance ? `instance/${instance}/` : '';
    const studentPathSegment =
        courseInstancePathSegment !== '' && student ?
            `student/${student}/` :
            '';

    const url = `${baseUrl}${coursePathSegment}${courseInstancePathSegment}${studentPathSegment}`;

    return url;
};

/**
 * Sorts an array of files by their scope in the order course > instance > student.
 */
export const sortByScope = (
    fileMetadataArray: StudentFileMetadata[]
): StudentFileMetadata[] | null => {
    try {
        if (!Array.isArray(fileMetadataArray)) {
            throw new Error('fileMetadataArray value is not an array.');
        }
        const weights = {
            course: 1,
            instance: 2,
            student: 3,
        };

        return fileMetadataArray.sort((a, b) => {
            return weights[a.scope] - weights[b.scope];
        });
    } catch (e) {
        if (e instanceof Error) {
            console.error(e.message);
        }

        return null;
    }
};

interface FetchStudentMyLabsFileParams {
    courseId: string;
    instanceId: string;
    studentId: string;
    fileId: string;
    token: string;
}

/**
 * Fetches a student file directly from the mylabs files API.
 */
export const fetchStudentMyLabsFile = async ({
    courseId,
    instanceId,
    studentId,
    fileId,
    token,
}: FetchStudentMyLabsFileParams) => {
    try {
        const url =
            getURL(courseId, instanceId, studentId) +
            `files/${fileId}?token=${token}`;

        const dataResponse = await fetch(url, {
            method: 'GET',
        });

        return dataResponse;
    } catch (e) {        
        if (axios.isAxiosError(e)) {
            console.error('Axios error:', e.name);
        } else if (e instanceof TypeError) {
            console.error('TypeError:', e.message);
        } else {
            console.error('Unexpected error:', e);
        }
        
        throw e;
    }
};

/**
 * Determines what course instance strings to use and returns an array with said strings.
 * 
 * This function is not used with version 2 of the MyLabs Files API.
 */
export const normalizeCourseInstances = (
    /** records will be a LabProduct array w/ 1 element */
    records: LabProduct[]
) => {
    // NOTE: There should only be one lab at any given point being processed here
    // It also doesn't make sense to fix this, as we've moved on to v2 which
    // does not use this function
    try {
        //? If we have a single object put it into a array and proceed as normal
        if (!Array.isArray(records)) {
            records = [records];
        }

        //? Return if we have no records
        if (records.length === 0) {
            return null;
        }

        return records.map((record) => {
            const {event_product_id: eventProductID} = record;
            const recordIsOnDemand = isLabOD(record);

            if (recordIsOnDemand) {
                return record.name.replace('_', '-');
            }

            return eventProductID!.toString();
        });
    } catch (e) {
        console.log(e);
        return null;
    }
};

/**
 * (Version 1) Request student files from the lambda. This will return a list of files the student
 * can download via the DynamicRenderer.
 */
export const fetchStudentFiles = async (
    /** The course catalog name (ie SEC498) */
    course: string,
    /** An array containing the course info for files to be requested */
    records: LabProduct[],
    /** The range instance, if one exists */
    range?: RangeInstance
) => {
    try {
        let courseInstances: string[] = [];

        //? records might be an array or js object, so we need to handle both options
        if (Array.isArray(records)) {
            const instances = normalizeCourseInstances(records);
            if (instances) {
                courseInstances = instances;
            }
        } else {
            //? If it's a JS object, we can stuff it into an array
            if (typeof records === 'object') {
                const recordsArr = [records];

                courseInstances = normalizeCourseInstances(recordsArr)!;
            }
        }

        if (courseInstances === null) {
            courseInstances = [];
        }

        if (range && range.product_catalog === course) {
            courseInstances.push(`jit-${range.id}`);
        } else if (typeof range === 'number') {
            courseInstances.push(`jit-${range}`);
        }

        const postData = {
            courseInstances,
        };

        const url = `${API_BASE}/files`;

        const requestOptions = {
            params: {
                course,
            },
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return await axios.post(url, postData, requestOptions);
    } catch (e) {
        console.error(e);
        throw e;
    }
};

/**
 * (Version 2) Request student files from the lambda. This will return all of the student's file.
 */
export const getStudentFiles = async (
    fileRequestObject: MyLabsFilesRequestPayload
): Promise<FilesResponse | null> => {
    const payload = fileRequestObject;

    const url = `${API_BASE}/files`;

    const requestOptions = {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const filesMetadataResponse = await axios.post(
            url,
            payload,
            requestOptions
        );
        return filesMetadataResponse.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        }
    }

    return null;
};

/**
 * reduce all files to an object who's keys are the course catalog prefixes
 */
export const aggregateFiles = (labs: LabProduct[], ranges: RangeInstance[]) => {
    const rangeRequestPayloads = ranges
        .filter((range) => {
            const now = new Date(Date.now());
            const expiration = new Date(range.expires_at);

            return expiration > now && range.status !== 'completed';
        })
        .reduce(
            (aggregatedRanges, currentRange) => {
                const {
                    id: rangeID,
                    product_catalog: productCatalog,
                    mylabs_part: mylabsPart,
                } = currentRange;

                const key = currentRange.product_catalog.slice(0, 6);

                if (!aggregatedRanges[key]) {
                    aggregatedRanges[key] = [];
                }
                aggregatedRanges[key]?.push({
                    rangeID,
                    productCatalog,
                    labName: mylabsPart,
                    instance: `jit-${rangeID}`,
                    type: 'range',
                });

                return aggregatedRanges;
            },
            {} as {
                [key: string]: RangeFileRequestPayloadSection[];
            }
        );

    const fileRequestPayloads = labs.reduce(
        (aggregatedLabs, currentLab) => {
            const {
                id: labID,
                event_product_id: eventProductID,
                name,
            } = currentLab;
            const labName = name.replaceAll('_', '-');
            const key = labName.slice(0, 6);
            const instance = !labName.includes('GEN') ? eventProductID : labName;
            if (aggregatedLabs[key] === undefined) {
                aggregatedLabs[key] = [];
            }
            
            if (instance){
                // This should never be undefined here, but typescript is complaining
                aggregatedLabs[key]!.push({
                    instance,
                    labID,
                    eventProductID,
                    labName,
                    type: 'file',
                });
            }

            return aggregatedLabs;
        },
        {} as {
            [key: string]: StudentFileRequestPayloadSection[];
        }
    );

    const finalPayload: FilesAPIRequestPayload = {
        ...fileRequestPayloads,
    };

    Object.entries(rangeRequestPayloads).forEach(
        ([key, rangeRequestPayload]) => {
            if (finalPayload[key] === undefined) {
                finalPayload[key] = rangeRequestPayload;
            } else {
                finalPayload[key] =
                    finalPayload[key]!.concat(rangeRequestPayload);
            }
        }
    );

    return finalPayload;
};
