/**
 * Returns the course catalog number (SEC100, SEC110, etc) from a longer lab name.
 *
 * ie, SEC100-X-Y-X => SEC100
 * @param {string} courseName
 * @returns {string} - The 6 or 7 char (if including course section 'A' or 'B')
 */
const getCourseCatalog = (courseName) => {
    const courseCatalog = courseName.substring(0, 6);
    const courseSection = courseName.charAt(7);

    if (courseSection) {
        return `${courseCatalog}${courseSection}`;
    }

    return courseCatalog;
};

// eslint-disable-next-line valid-jsdoc
/**
 * Sort courses into alphabetical order by `course_name`.
 * @param {*} a
 * @param {*} b
 * @returns
 */
const sortCourses = (a, b) => {
    // a & b are arrays with lab records
    // all instructions should share the same course_name, so we pull the first one to compare
    const courseA = a[0].course_name && a[0].course_name.toLowerCase();
    const courseB = b[0].course_name && b[0].course_name.toLowerCase();

    // if something went wrong for some reason (ie, we couldn't pull both names), leave sorting as it is
    if (!courseA || !courseB) {
        return 0;
    }

    return courseA.localeCompare(courseB);
};

export { getCourseCatalog, sortCourses };
