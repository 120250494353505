import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppConfiguration } from '../config/appConfig';

interface ConfigSliceState {
    config: AppConfiguration | object;
}

const INITIAL_CONFIG_STATE: ConfigSliceState = {
    config: {},
};

export const configSlice = createSlice({
    name: 'config',
    initialState: INITIAL_CONFIG_STATE,
    reducers: {
        setConfig: (state, action: PayloadAction<AppConfiguration>) => {
            const { payload } = action;

            state.config = payload;
        },
    },
});

export const { setConfig } = configSlice.actions;

export default configSlice.reducer;
