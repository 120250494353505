import './home.scss';
import React, { useEffect, useState } from 'react';
import { useStringFlagDetails } from '@openfeature/react-sdk';

import LabDisplay from '../../submodules/shared/components/lab-display/lab-display';
import Loading from '../../submodules/shared/components/loading';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentFiles, aggregateFiles } from '../../submodules/shared/utils/mylabs-files-api';
import { setFiles } from '../../features/filesSlice';
import { Notifications } from '../../submodules/shared/components/notifications/notifications';
import filesApiProxyFeatureFlag from '../../config/features/flags/filesApiVersion';
const Home = () => {
    // Get our features config from the store
    const filesApiFeatureFlag = useStringFlagDetails(
        filesApiProxyFeatureFlag.metadata.flagName,
        filesApiProxyFeatureFlag.forwardedValue ||
            filesApiProxyFeatureFlag.configuration.defaultVariant,
    );

    const [labResults, setLabResults] = useState(); //initially null - this lets us know we are loading...
    const errorMsg = useSelector((state) => state.labReducer.error);
    const loading = useSelector((state) => state.labReducer.isLoading);
    const labs = useSelector((state) => state.labReducer.labResults);
    const ranges = useSelector((state) => state.rangeProvisioningReducer.ranges);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchFiles = async () => {
            if (labResults !== labs && labs) {
                setLabResults(labs);
                if (filesApiFeatureFlag.value === 'v2') {
                    // If we're using using V1 files API interaction, we don't want this to execute
                    const res = await getStudentFiles(aggregateFiles(labs, ranges));

                    dispatch(setFiles(res));
                }
            }
        };

        fetchFiles().catch((error) => console.log(error));
    }, [labs]);

    let mainContent;

    if (loading && !errorMsg) {
        mainContent = (<Loading message="Fetching lab information..."/>);
    } else if (errorMsg) {
        mainContent = (<Notifications messages={ [errorMsg] } />);
    }
    if (labResults && labResults.length > 0) {
        mainContent = (<LabDisplay labResults={ labResults } />);
    }

    return (
        <div className="home">
            <div className="sans-header">
                <div className="logo"></div>
                <div className="welcome">
                    <a
                        // href={ `${configReducer.config.globalEndpoints.sansURL}/account` }
                        href="https://www.sans.org/account/"
                        title="Account Dashboard"
                        aria-label="Account Dashboard"
                    ></a>
                </div>
            </div>
            <div className="ribbon">
                <h1>Licensing and Range Environment Access</h1>
            </div>
            <div className="content">{mainContent}</div>
            <div className="footer">
                <p>&copy; {new Date().getFullYear()} SANS&trade; Institute</p>
                <a href="https://www.sans.org/privacy/">Privacy Policy</a>
            </div>
        </div>
    );
};

export default Home;
