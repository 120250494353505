/**
 * Reads a text file using the FileReader, in a promisified manner
 * @param {File} file - The file to read
 * @returns The text file's data as a string
 */
export const readFileToString = (file) => {
    const promisifiedRead = (resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            resolve(event.target.result);
        };

        reader.onerror = (err) => {
            reject(err);
        };

        reader.readAsText(file);
    };

    return new Promise(promisifiedRead);
};
