import * as R from 'ramda';

export const textToFile = (blob, filename) => {
    if (R.pathOr(false, ['navigator', 'msSaveOrOpenBlob'], window)) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        const e = document.createEvent('MouseEvents');
        const a = document.createElement('a');

        a.download = filename;
        // eslint-disable-next-line
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
        e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        a.dispatchEvent(e);
    }
};
