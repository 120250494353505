import React, { useState } from 'react';
import { getShodanLicense } from '../../../utils/api-licensing';
import LicenseDisplayBasic from '../license-display/license-display-basic';
import LicenseError from '../license-error/license-error';
import LicenseFormSubmitButton from '../license-form-submit-button/license-form-submit-button';
import PropTypes from 'prop-types';
import '../license-form.scss';

const Shodan = ({ labData }) => {
    const [license, setLicense] = useState(null);
    const [licenseError, setLicenseError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getLicense = async () => {
        try {
            setIsSubmitting(true);
            const licenseResponse = await getShodanLicense(labData);

            if (licenseResponse.license) {
                setLicense(licenseResponse.license);
                setLicenseError(null);
            } else {
                setLicenseError(licenseResponse.error);
                setLicense(null);
            }
        } catch (e) {
            setLicenseError('An error has occured while trying to retrieve your Shodan license.');
            // eslint-disable-next-line no-console
            console.log(e);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <section className="license-form">
            {/* eslint-disable-next-line max-len */}
            <LicenseFormSubmitButton onClick={ () => getLicense() } disabled={ !!isSubmitting || !!licenseError || !!license } submitting={ isSubmitting }>Get Shodan License</LicenseFormSubmitButton>
            {license && <LicenseDisplayBasic license={ license } licenseText="Your gift code is: " />}
            {licenseError && <LicenseError error={ licenseError } />}
        </section>
    );
};

Shodan.propTypes = {
    labData: PropTypes.object,
};

export default Shodan;
