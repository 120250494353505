import React, { useState } from 'react';
import LicenseDisplayBasic from '../license-display/license-display-basic';
import LicenseError from '../license-error/license-error';
import LicenseFormSubmitButton from '../license-form-submit-button/license-form-submit-button';
import PropTypes from 'prop-types';
import '../license-form.scss';
import LicenseDisplayWithChildren from '../license-display/license-display-with-children';

const DEFAULT_ERROR_MESSAGE =
  'An error has occured while trying to retrieve your license.';

/**
 * A component for simple 'pushbutton' license requests,
 * where the user acquires their license after simply
 * pushing a button.
 * @returns {JSX.Element}
 */
const PushbuttonLicense = ({
    labData,
    licensingIntegrationFn,
    content,
}) => {
    const [license, setLicense] = useState(null);
    const [licenseData, setLicenseData] = useState(null);
    const [licenseError, setLicenseError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionComplete, setSubmissionComplete] = useState(false);
    const {
        buttonText,
        bodyText,
        htmlBody,
        successText,
    } = content;
    /**
   * Get a license by way of a provided function
   * `licensingIntegrationFn` prop.
   */
    const getLicense = async () => {
        try {
            setIsSubmitting(true);
            const licenseResponse = await licensingIntegrationFn(labData);

            if (licenseResponse.error) {
                throw new Error(licenseResponse.error);
            }

            if (licenseResponse.license) {
                setLicense(licenseResponse.license);
                setLicenseError(null);
            }

            if (licenseResponse.licenseData) {
                setLicenseData(licenseResponse.licenseData);
                setLicenseError(null);
            }
        } catch (e) {
            setLicenseError(e.message || DEFAULT_ERROR_MESSAGE);
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            setIsSubmitting(false);
            setSubmissionComplete(true);
        }
    };

    return (
        <section className="license-form">
            {
                bodyText &&
                    <section>
                        <p>{bodyText}</p>
                    </section>
            }
            {
                htmlBody &&
                    <section>
                        <div dangerouslySetInnerHTML={ { __html: htmlBody } }/>
                    </section>
            }
            <LicenseFormSubmitButton
                onClick={ () => getLicense() }
                disabled={
                    !!isSubmitting || !!licenseError || !!license || submissionComplete
                }
                submitting={ isSubmitting }
            >
                {buttonText}
            </LicenseFormSubmitButton>
            {license && <LicenseDisplayBasic license={ license } licenseText={ successText } />}
            {licenseData &&
                <LicenseDisplayWithChildren>{ content.successTextTransformer ?
                    content.successTextTransformer(licenseData) :
                    successText
                }</LicenseDisplayWithChildren>}

            {licenseError && <LicenseError error={ licenseError } />}
        </section>
    );
};

PushbuttonLicense.defaultProps = {
    content: {
        buttonText: 'Get License',
        bodyText: '',
        htmlBody: '',
    },
};

PushbuttonLicense.propTypes = {
    /** Extra information about the lab */
    labData: PropTypes.object.isRequired,
    /** The licensing callback function */
    licensingIntegrationFn: PropTypes.func,
    /** Content controls */
    content: PropTypes.shape({
        /** Text shown on the submit button */
        buttonText: PropTypes.string,
        /** Body text of the licensing integration */
        bodyText: PropTypes.string,
        /** HTML body for the licensing integration. */
        htmlBody: PropTypes.string,
        /** Optional text to display upon success. Overrides original success text. */
        successText: PropTypes.string,
        /** Optional function to transform the success text */
        successTextTransformer: PropTypes.func,
    }),
};

export default PushbuttonLicense;
