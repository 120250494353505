/**
 * Return a boolean `true` if the course lab is an OD lab
 * @param {Object} lab
 * @param {string} lab.name - The lab name (format `SEC000-A01-AWS-LIVE`)
 * @param {number} lab.event_id - The event id of the lab
 * @returns {boolean} True if the lab is an OD lab, false otherwise
 */
const isLabOD = (lab) => {
    const ONDEMAND_EVENT_ID = 1032;

    if (typeof lab !== 'object') {
        throw new Error('isLabOD::lab is not an object');
    }

    if (!Object.prototype.hasOwnProperty.call(lab, 'name')) {
        throw new Error('isLabOD::lab missing "name" property');
    }

    if (!Object.prototype.hasOwnProperty.call(lab, 'event_id')) {
        throw new Error('isLabOD::lab missing "event_id" property');
    }

    if (lab.name.includes('GEN')) {
        return true;
    }

    // 1032 is used for most (but not all) OD instances
    if (lab.event_id === ONDEMAND_EVENT_ID) {
        return true;
    }

    return false;
};

/**
 * Comparitor for sortLabs.
 * @param {*} labA
 * @param {*} labB
 * @returns {number} 0 if labsA and B are both OD. -1 if A is NOT OD and B is. 1 otherwise.
 */
const compareODAndLiveLab = (labA, labB) => {
    if (isLabOD(labA) && isLabOD(labB)) {
        return 0;
    }
    if (isLabOD(labA) && !isLabOD(labB)) {
        return 1;
    }

    if (!isLabOD(labA) && isLabOD(labB)) {
        return -1;
    }

    return 0;
};

/**
 * Sorts labs by course name.
 * @param {*} labA
 * @param {*} labB
 * @returns {number} 0, >=1, or <=-1
 */
const sortLabs = (labA, labB) => {
    const labNameA = labA.name && labA.name.toLowerCase();
    const labNameB = labB.name && labB.name.toLowerCase();

    return (
        labB.user_product_cache_id - labA.user_product_cache_id ||
        labB.lab_start - labA.lab_start ||
        labNameA.localeCompare(labNameB)
    );
};

/**
 * Sort labs by LIVE or OD. OD types are pushed to the bottom.
 * @param {object} labA - The Lab object to compare (and move up or down)
 * @param {object} labB - The Lab object to compare against
 * @returns {number} A number 0 if labs are the same type, 1 if lab A is OD, and -1 otherwise.
 */
const sortLabsByType = (labA, labB) => {
    return compareODAndLiveLab(labA, labB);
};

/**
 * Extracts and infers vital metadata from Labs
 * @param {Lab} lab
 * @returns {LabData} Abbreviated labData
 */
const extractLabData = (lab) => {
    return {
        courseName: lab.course_name,
        courseCatalogName: lab.name.substring(0, 6),
        name: lab.name,
        eventId: lab.event_id,
        eventProductId: lab.event_product_id,
        instructionsCount: Object.keys(lab.instructions).length,
        section: lab.section,
        modality: lab.name.includes('GEN') ? 'OnDemand' : 'Live',
    };
};

export { sortLabs, sortLabsByType, compareODAndLiveLab, isLabOD, extractLabData };
