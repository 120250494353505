export type ChainalysisLicenseData = {
    username: string;
    password: string;
}

/**
 * Transforms Chainalysis license data into a React component.
 */
const transformChainalysisLicense = (licenseData: ChainalysisLicenseData) => { 
    const { username, password } = licenseData;

    return (
        <div>
            <p>You can access Chainalysis with the following credentials: <br />
                Reactor Username: <strong>{username}</strong><br/>
                Reactor Password: <strong>{password}</strong>
            </p>
        </div>
    );
}

export default transformChainalysisLicense;
