import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { REGION_DISPLAY_NAMES } from './constants';
import './regionSelector.css';

/**
 * Renders a dropdown menu for selecting a preferred AWS region for a dynamically
 * provisioned range.
 *
 * @param {Object} props React props passed into the component.
 * @param {import ('../../../../types/types').RangeRule} props.rule - Data about the course range.
 * @param {string} props.defaultRegion - The default region to select when the component mounts.
 * @param {function} props.handleChange A function for handling "change" events.
 * @returns {JSX.Element}
 */
const RegionSelector = ({
    rule,
    defaultRegion,
    handleChange,
}) => {
    const [selected, setSelected] = useState(defaultRegion);

    /**
     * @param {string} region
     */
    const display = (region) => {
        return `${region} (${REGION_DISPLAY_NAMES[region]})` || region;
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} event
     */
    const handleSelectionChange = (event) => {
        setSelected(event.target.value);
        handleChange(event.target.value);
    };

    return (
        <div className="region-selector">
            <label htmlFor="region">Select your preferred region:</label>
            <select id="region" name="region" value={ selected } onChange={ handleSelectionChange }>
                {rule.aws_regions.map((region) => (
                    <option key={ region } value={ region }>
                        {display(region)}
                    </option>
                ))}
            </select>
        </div>
    );
};

RegionSelector.propTypes = {
    /**
     * The range provision definition. Support regions will be pulled from
     * this object.
     */
    rule: PropTypes.object.isRequired,

    /**
     * The default region to select when the component mounts.
     */
    defaultRegion: PropTypes.string,

    /**
     * The function to call when the region is changed.
     */
    handleChange: PropTypes.func.isRequired,
};

export default RegionSelector;
