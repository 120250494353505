import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * Basic account data for MyLabs users. All values are 
 * initialized as `null`.
 */
export interface AccountSliceState {
    sansAccountID: string | null;
    sansEmail: string | null;
    name: string | null;

}

const INITIAL_ACCOUNT_STATE: AccountSliceState = {
    sansAccountID: null,
    sansEmail: null,
    name: null,
};

export const accountSlice = createSlice({
    name: 'account',
    initialState: INITIAL_ACCOUNT_STATE,
    reducers: {
        /** Set authenticated user's `sansAccountID`, `sansEmail`, and `name` into storage */
        setAccountDetails: (state, action: PayloadAction<AccountSliceState>) => {
            const {
                payload: {
                    sansAccountID,
                    sansEmail,
                    name,
                },
            } = action;

            state.sansAccountID = sansAccountID;
            state.sansEmail = sansEmail;
            state.name = name;
        },
    },
});

export const { setAccountDetails } = accountSlice.actions;

export default accountSlice.reducer;
