import appConfig from '../../config/appConfig';
import { TestFileMetadata } from '../files/data';

export const TestConfigState = ({
    globalEndpoints = {
        sansURL: 'test.sans.org',
    },
    integrations = {
        cloudProviders: {
            providers: {
                default: ['Azure'],
            },
        },
    },
    features = {
        filesApiProxy: {
            getStudentFiles: {
                version: 1,
            },
        },
    },
    version = '1.0.0',
} = {}) => ({
    ...appConfig(),
    globalEndpoints,
    integrations,
    features,
    version,
    runtimeEnv: 'test',
});

export const TestFilesStateSingle = (lab, { fileName = undefined } = {}) => ({
    files: {
        [lab.name]: {
            eventProductId: lab.event_product_id,
            labID: lab.id,
            labName: lab.name,
            instance: lab.name,
            metadata: [TestFileMetadata(lab, { fileName })],
        },
    },
});
