import React from 'react';
import PropTypes from 'prop-types';

const LicenseDisplayWithChildren = ({ children }) => {
    return (
        <section className="licensing-display">
            {children}
        </section>
    );
};

LicenseDisplayWithChildren.propTypes = {
    children: PropTypes.object,
};

export default LicenseDisplayWithChildren;
