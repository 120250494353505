import { TestLab } from '../data';
import { generateMockCourses } from './generateMockCourses';

const generateLabMocks = (catalogMock) => {
    const mocks = {};

    // For each course catalog name in the mock
    Object.entries(catalogMock).forEach(([courseCatalogStr, courseCatalogData]) => {
        // Create an entry for a mock for a the current courseCatalog
        // We will populate it in later steps
        mocks[courseCatalogStr] = {};

        // Get the Live and OnDemand modalities
        Object.entries(courseCatalogData).forEach(([courseModalityStr, courseModalityData]) => {
            // Live and OD have slightly differing structures
            if (courseModalityStr === 'live') {
                // If the course is Live, the top level keys of modalityData are eventIds (instance)
                // We need to get the instance data and the section data
                Object.entries(courseModalityData).forEach(([instance, instanceData]) => {
                    // If modalityData has a defined key, that's the current instance
                    let currData;

                    if (mocks[courseCatalogStr][courseModalityStr]) {
                        // Here, we may have already defined our object up to the modality
                        // If we have, there might be current data there for the instance
                        // (ie, we've already processed section A and are processing B)
                        currData =
                            mocks[courseCatalogStr][courseModalityStr][instance];
                    }

                    // Set our instance data
                    // Make sure that we don't overwrite existing data as well
                    mocks[courseCatalogStr][courseModalityStr] = {
                        ...mocks[courseCatalogStr][courseModalityStr],
                        [instance]: { ...currData },
                    };

                    Object.entries(instanceData).forEach(([section, sectionData]) => {
                        // Get the data for this section

                        // Set up an object to contain the instructions
                        const sectionInstructions = {};

                        // Gather each set of instructions for the section
                        if (sectionData.instructions.overview) {
                            sectionInstructions.Overview =
                                Object.values(
                                    sectionData.instructions
                                        .overview
                                );
                        }

                        if (
                            sectionData.instructions.licensing
                        ) {
                            sectionInstructions.Licensing =
                                Object.values(
                                    sectionData.instructions
                                        .licensing
                                );
                        }

                        // Build the params object
                        const labParams = {
                            ...sectionData.labData,
                            instructions: {},
                            courseName: `${courseCatalogStr.toUpperCase()}: ${
                                courseModalityStr === 'live' ?
                                    courseCatalogData[courseModalityStr][instance][section]
                                        .labData.testExtras.courseTitle :
                                    courseCatalogData[courseModalityStr][section]
                                        .labData.testExtras.courseTitle
                            } *`,
                            additionalInstructions: {
                                ...sectionInstructions,
                            },
                        };

                        // Build the lab with the data we just managed
                        const sectionLab = TestLab(labParams);

                        // Check if there is existing data at it's course, modality, and instance
                        mocks[courseCatalogStr][courseModalityStr][instance][
                            section
                        ] = sectionLab
                    });
                });
            } else {
                Object.entries(courseModalityData).forEach(([section, sectionData]) => {
                    let currData;

                    if (mocks[courseCatalogStr][courseModalityStr]) {
                        currData =
                            mocks[courseCatalogStr][courseModalityStr];
                    }

                    mocks[courseCatalogStr][courseModalityStr] = {
                        ...mocks[courseCatalogStr][courseModalityStr],
                        [section]: { ...currData },
                    };

                    const sectionInstructions = {};

                    if (sectionData.instructions.overview) {
                        sectionInstructions.Overview =
                            Object.values(
                                sectionData.instructions
                                    .overview
                            );
                    }

                    if (
                        sectionData.instructions.licensing
                    ) {
                        sectionInstructions.Licensing =
                            Object.values(
                                sectionData.instructions
                                    .licensing
                            );
                    }
                    const labParams = {
                        ...sectionData.labData,
                        instructions: {},
                        courseName: `${courseCatalogStr.toUpperCase()}: ${
                            courseCatalogData[courseModalityStr][section]
                                .labData.testExtras.courseTitle 
                        } *`,
                        additionalInstructions: {
                            ...sectionInstructions,
                        },
                    };

                    const sectionLab = TestLab(labParams);

                    mocks[courseCatalogStr][courseModalityStr][section] = sectionLab;
                });
            }
        });
    });
    return mocks;
};

export const generatedLabMocks = generateLabMocks(generateMockCourses());
