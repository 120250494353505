import PropTypes from 'prop-types';
import React from 'react';
// Only show a feature if the logged-in user's email address is a sans email address
const FeatureHide = ({ loggedInUserEmail, children }) => {
    if (!loggedInUserEmail) {
        return null;
    }
    if (!loggedInUserEmail.endsWith('@sans.org')) {
        return null;
    }
    const fullTesters = process.env.REACT_APP_MYLABS_FEATURE_TEST.split(',');
    const devTesters = process.env.REACT_APP_MYLABS_FEATURE_TEST_DEV.split(',');
    const featureTesters = [].concat(fullTesters, location.hostname !== 'connect.labs.sans.org' ? devTesters : []);

    if (!featureTesters.includes(loggedInUserEmail.split('@')[0])) {
        return null;
    }

    return <React.Fragment>{children}</React.Fragment>;
};

FeatureHide.propTypes = {
    loggedInUserEmail: PropTypes.string,
    children: PropTypes.element.isRequired,
};

export default FeatureHide;
