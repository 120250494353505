import courseConstants from './courseConstants.json';

export const generateMockCourses = () => {
    const courses = {};

    Object.entries(courseConstants).forEach(([course, courseData]) => {
        // Split out the common data, event names, and modality specific data
        const { commonLabData, eventData, ...modalities } = courseData;

        courses[course] = {};

        Object.entries(modalities).forEach(([modality, modalityData]) => {
            // For each modality key (live and/or onDemand)...
            // Get the individual modality

            // Here, we need to take care the final object is appropriately formatted
            // OD/Live labs have differing structures. OD Lab's immediate children
            // are the A and B keys for their courses.
            // Live Lab's immediate children are the instance ids of those labs
            // (The children of those labs are the A and B keys)
            Object.entries(modalityData).forEach(([section, sectionData]) => {
                // if our modality is live, we need to get the Live instances,
                // which are the keys of the the lab's 'eventNames'
                const s = {
                    ...sectionData,
                    labData: {
                        ...sectionData.labData,
                        ...commonLabData,
                    },
                };

                if (modality === 'live') {
                    for (const instance in eventData) {
                        if (Object.hasOwn(eventData, instance)) {
                            let currData;

                            if (courses[course][modality]) {
                                currData = courses[course][modality][instance];
                            }

                            courses[course][modality] = {
                                ...courses[course][modality],
                                [instance]: { ...currData },
                            };

                            courses[course][modality][instance] = {
                                ...courses[course][modality][instance],
                                [section]: {
                                    ...s,
                                    labData: {
                                        ...s.labData,
                                        ...eventData[instance].labData,
                                    },
                                },
                            };
                        }
                    }
                } else {
                    courses[course][modality] = {
                        ...courses[course][modality],
                        [section]: s,
                    };
                }
            });
        });
    });
    // console.log(JSON.stringify(courses, null, '\t'));
    // console.log(courses);
    return courses;
};

export const generatedMocks = generateMockCourses();
