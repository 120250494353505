import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
/**
 * Renders an anchor element and simulates a click on it.
 * @param {object} props
 * @param {Blob} props.fileBlob - The Blob object (usually from an HTTP response) to create a download URL for
 * @param {Function} props.cb - An optional function to execute after the simulated mouse click
 * @returns -
 */
export const FileDownloader = ({ fileBlob, cb, downloadName }) => {
    const [fileUrl] = useState(URL.createObjectURL(fileBlob));
    const downloadAnchorRef = useRef();

    // NOTE: We need to simulate a click on the link here to trigger the download.
    // element.click() is not exposed via refs, so we dispatch
    // events that constitute a mouse click.
    const simulateMouseClick = (element) => {
        const mouseClickEvents = ['mousedown', 'click', 'mouseup'];

        mouseClickEvents.forEach((mouseEventType) =>
            element.dispatchEvent(
                new MouseEvent(mouseEventType, {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1,
                }),
            ),
        );
    };

    useEffect(() => {
        simulateMouseClick(downloadAnchorRef.current);
        URL.revokeObjectURL(fileUrl);
        cb && cb();
    }, []);
    return (
        <a
            ref={ downloadAnchorRef }
            href={ fileUrl }
            download={ downloadName }
            target="_blank"
            rel="noopener noreferrer"
        ></a>
    );
};

FileDownloader.propTypes = {
    fileBlob: PropTypes.object,
    cb: PropTypes.func,
    downloadName: PropTypes.string,
};
