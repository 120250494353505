import React from 'react';
import './license-form-submit-button.scss';
import PropTypes from 'prop-types';
const LicenseFormSubmitButton = ({
    disabled,
    submitting,
    onClick,
    children,
}) => {
    return (
        <React.Fragment>
            {submitting ? (
                <div className="licensing-submit-btn__download-spinner" data-testid="download-spinner"/>
            ) : (
                <button
                    type="submit"
                    className='licensing-submit-btn'
                    data-testid="license-submit"
                    onClick={ onClick && onClick }
                    disabled={ submitting || disabled }>
                    {children}
                </button>
            )}
        </React.Fragment>
    );
};

LicenseFormSubmitButton.propTypes = {
    /** Is the button currently disabled? */
    disabled: PropTypes.bool,
    /** Is the button currently submitting? */
    submitting: PropTypes.bool,
    /** A callback to fire when the button is clicked */
    onClick: PropTypes.func,
    /** The inner content of the button */
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default LicenseFormSubmitButton;
