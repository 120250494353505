import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import * as R from 'ramda';

const BOOTSTRAP_ALERTS = ['warning', 'danger', 'info', 'success'];

const formatMessages = (messages) => {
    const formatted = {};

    R.forEach((item) => {
        if (!formatted[item.type]) {
            formatted[item.type] = [];
        }
        formatted[item.type].push(item);
    }, messages);

    return formatted;
};

const createMarkup = (message) => {
    return { __html: message };
};

export const Notifications = (props) => {
    const formattedMessages = formatMessages(props.messages);

    let key = -1;

    return (
        <div className="Notifications"> {
            R.map((type) =>
                R.propOr(false, [type], formattedMessages) && (
                    <Alert
                        data-type={ type }
                        key={ type }
                        variant={ type }
                        onClose={ props.hideNotificationItem }
                    >
                        {
                            R.map(({ message }) => (
                                <p key={ key++ } dangerouslySetInnerHTML={ createMarkup(message) }/>
                            ), formattedMessages[type])
                        }
                    </Alert>
                ),
            BOOTSTRAP_ALERTS)
        } </div>
    );
};

Notifications.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(BOOTSTRAP_ALERTS),
            message: PropTypes.string,
        })
    ),
    hideNotificationItem: PropTypes.func,
};
